

















































































@import '@design';
.is-dirty {
  min-height: 10px;
}
.addon-list-enter-active,
.addon-list-leave-active {
  transition: all 0.2s;
}
.addon-list-enter, .addon-list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0.1;
  transform: translateX(10px);
}
